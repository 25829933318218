'use client';

import Link from 'next/link';
import { useEffect } from 'react';

export default function NotFound() {
  useEffect(() => {
    // Update the document title
    document.title = '404 - Η σελίδα δεν βρέθηκε | Kairos.gr';
  }, []);

  return (
    <div className="flex items-center justify-center" style={{ minHeight: 'calc(100vh - 250px)' }}>
      <div className="text-center">
        <h1 className="text-[80px] leading-none font-bold text-gray-900 mb-3">404</h1>
        <h2 className="text-xl text-gray-900 mb-3">Η σελίδα δεν βρέθηκε</h2>
        <Link
          href="/"
          className="text-blue-500 hover:text-blue-600 inline-flex items-center gap-2"
        >
          <span>Αρχική Σελίδα</span>
        </Link>
      </div>
    </div>
  );
}